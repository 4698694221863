import axiosApi from "@/services/axiosApi";
import {AxiosResponse} from "axios";

export const categories = {
  namespaced: true,
  state: {
    categories: [],
  },
  mutations: {
    SET_CATEGORIES: (state: any, categories: any) => {
      state.categories = categories
    }
  },
  getters: {
    GET_PARENT_ID: (state: any) => {
      return state.categories.breadCrumbs?.parent.id
    }
  },
  actions: {
    async getCategories({ commit }: any, { serviceId, restaurant = null }: any): Promise<any> {
      try {
        const response: AxiosResponse<any, any> = await axiosApi.get(`${process.env.VUE_APP_API_URL}company/categories/${serviceId}/${restaurant}`);
        commit('SET_CATEGORIES', response.data.data);
        return response.data.data;
      } catch (error) {
        console.error('Error fetching categories:', error);
        throw error;
      }
    }
  }
}
