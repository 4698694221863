import Echo from 'laravel-echo'

export function createEcho(token: string) {
    return new Echo({
      broadcaster: 'pusher',
      key: process.env.VUE_APP_PUSHER_APP_KEY,
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      encrypted: true,
      authEndpoint: process.env.VUE_APP_PUSHER_BASE_URL + process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
      auth: {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    });
}
