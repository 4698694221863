import axiosApi from "@/services/axiosApi"
import { AxiosResponse } from "axios"
import { CompanyState } from "@/interfaces/CompanyState"

export const companies = {
  state: (): CompanyState => ({
    header: [],
    restaurant_logo: '',
  }),
  getters: {
    GET_HEADER: (state: CompanyState) => state.header,
    GET_RESTAURANT_LOGO: (state: CompanyState) => state.restaurant_logo
  },
  mutations: {
    SET_HEADER(state: CompanyState, headers: any[]): void {
      state.header = headers
    },
    SET_RESTAURANT_LOGO(state: CompanyState, logo: string){
      return state.restaurant_logo = logo
    }
  },
  actions: {
    async getHeaderData({commit}: { commit: (...args: any[]) => void }, { companyId, lang }: {
      companyId: string,
      lang: string
    }): Promise<void> {
      try {
        const {data}: AxiosResponse = await axiosApi.get(`${process.env.VUE_APP_API_URL}company/${companyId}/header`)
        commit('SET_HEADER', data.data)
      } catch (e: any) {
        console.error(e.messages)
      }
    }
  }
}
