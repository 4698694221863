import { getCompanyId } from '@/utils/routerParams'

type MyBagItemType = {
  id:string,
  count:number
}

const MY_BAG_KEY = 'mabell_order_basket'

const getStorageData = () => {
  const storageData = localStorage.getItem(MY_BAG_KEY);
  return storageData ? JSON.parse(storageData) : {};
}

const setMyBagItems = (items: Array<MyBagItemType>):void => {
  const storageData = getStorageData()
  const companyId = getCompanyId()
  if(companyId) {
    storageData[companyId] = prepareMyBagItemsToSave(items)
  }
  localStorage.setItem(MY_BAG_KEY, JSON.stringify(storageData))
}

const getMyBagItems = ():Array<MyBagItemType> => {
  const storageData = getStorageData()
  return storageData[getCompanyId() ?? ''] ?? [];
}

const prepareMyBagItemsToSave = (items: Array<MyBagItemType>): Array<MyBagItemType> => {
  return items.map(({ id, count }) => ({ id, count }));
}

export {
  setMyBagItems,
  getMyBagItems
}
