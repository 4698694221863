import axios from 'axios'
import router from '@/router'
import {getCurrentLang, transformLang} from "@/i18n/locales";

const axiosApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

axiosApi.defaults.headers.common['Content-Type'] = 'application/json'
axiosApi.defaults.headers.common.Accept = 'application/json'
axiosApi.defaults.headers.common['Accept-Language'] = transformLang(getCurrentLang(window.location.href)).toLowerCase();

export const setAuthHeader = (token: string|null) => {
  axiosApi.defaults.headers.common.Authorization = `Bearer ${token}`
}
const tokenJson = localStorage.getItem('mabell_auth_token')
const token = tokenJson ? JSON.parse(tokenJson) : {}
if (Object.prototype.hasOwnProperty.call(token, 'access_token')) {
  setAuthHeader(token.access_token)
}

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error, 'error')
    switch (error.response.status) {
      case 401:
        localStorage.removeItem('mabell_auth_token')
        localStorage.removeItem('mabell_chat_id')
        localStorage.removeItem('mabell_user_id')
        router.go(0)
        break
      default:
        return error
    }
  })

export default axiosApi
